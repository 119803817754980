const JSZip = require("jszip");
const gerberToSvg = require("gerber-to-svg");
const whatsThatGerber = require("whats-that-gerber");

export default {
  data() {
    return {
      gerber_files: null,
      dragIn: false,
      isLoading: false,
      artFiles: null,
      dirFolders: null,
      otherFiles: null,
      invalidGerbers: [],
      MAXIMUM_SECONDS_PARSE_LONG: 1e4,
      MAXIMUM_SECONDS_TO_PARSE: 12e4,
      formTopCopper: null,
      formTopSoldermask: null,
      formTopSilkscreen: null,
      formBottomCopper: null,
      formBottomSoldermask: null,
      formBottomSilkscreen: null,
      formAllMechanical: null,
      formAllDrill: null,
      withExistingGerbers: false,
      zipFileContent: null,
      disableAdminMode: false,
    };
  },
  computed: {
    zipGerberFiles() {
      return this.$store.getters["dutconfigs/zipGerberFiles"];
    },
    uiLoading() {
      return this.$store.getters["ui/loading"];
    },
    gerberFiles() {
      return [...this.zipGerberFiles].sort((a, b) =>
        a.side > b.side ? 1 : -1
      );
    },
    savedGerbers() {
      return this.gerbers;
    },
    topCopperMess() {
      return this.topCopperContainer ? "" : "Top Copper ";
    },
    topSolderMaskMess() {
      return this.topSoldermaskContainer ? "" : "Top SolderMask, ";
    },
    topSilkscreenrMess() {
      return this.topSilkscreenContainer ? "" : "Top SilkScreen, ";
    },
    bottomCopperMess() {
      return this.bottomCopperContainer ? "" : "Bottom Copper, ";
    },
    bottomSoldermaskMess() {
      return this.bottomSoldermaskContainer ? "" : "Bottom SolderMask, ";
    },
    bottomSilkscreenMess() {
      return this.bottomSilkscreenContainer ? "" : "Bottom SilkScreen, ";
    },
    allMechanicalMess() {
      return this.allMechanicalContainer.length > 0 ? "" : "Mechanical Layer, ";
    },
    allDrillMess() {
      return this.allDrillContainer.length > 0 ? "" : "Drill/NC FIle ";
    },
  },
  methods: {
    hybridRules({ required }) {
      const rules = [];
      if (required && (!this.isAdmin || this.disableAdminMode)) {
        const rule = (v) => !!v || "Input is required";
        rules.push(rule);
      }
      return rules;
    },
    dragover(event) {
      event.preventDefault();
      this.dragIn = true;
    },
    dragleave(event) {
      event.preventDefault();
      this.dragIn = false;
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.gerber_files = this.$refs.file.files;
      this.onConvert(); // Trigger the onConvert event manually
      this.dragIn = false;
    },
    async onConvert() {
      this.gerber_files = this.$refs.file.files[0];
      console.log(this.gerber_files);
      if (!this.gerber_files) {
        this.$store.commit("dutconfigs/INIT_ZIP_GERBER_FILES");
        return;
      }
      try {
        const zip = new JSZip();
        const response = await zip.loadAsync(this.gerber_files);
        const validFiles = Object.keys(response.files).reduce(
          (reducer, element) => {
            if (element.split("/").pop().length === 0) {
              reducer.dir.push(element);
            } else if (element.split(".").pop() !== "art") {
              reducer.mapped.push(element);
            } else {
              reducer.art.push(element);
            }
            return reducer;
          },
          { dir: [], mapped: [], art: [], other: [] }
        );
        this.artFiles = validFiles.art;
        this.dirFolders = validFiles.dir;
        this.toggleLoading("Parsing Gerber Files");
        this.isLoading = true;
        const tsBefore = new Date();
        let loaderUpdated = false;
        for (let i = 0; i < validFiles.mapped.length; i++) {
          const tsAfter = new Date();
          if (
            !loaderUpdated &&
            tsAfter - tsBefore >= this.MAXIMUM_SECONDS_PARSE_LONG
          ) {
            this.toggleLoading();
            this.toggleLoading(
              "Still parsing your gerbers, they might be too large"
            );
            loaderUpdated = true;
          }
          const fileObj = validFiles.mapped[i];
          const filename = fileObj.split("/").pop();
          const fileext = filename.split(".").pop();
          const content = await zip.file(fileObj).async("nodebuffer");
          const layerObj = whatsThatGerber([filename]);
          console.log("What's-that-gerber", layerObj);
          if (
            (layerObj[filename].side == "top" &&
              (layerObj[filename].type == "copper" ||
                layerObj[filename].type == "silkscreen" ||
                layerObj[filename].type == "soldermask")) ||
            (layerObj[filename].side == "bottom" &&
              (layerObj[filename].type == "copper" ||
                layerObj[filename].type == "silkscreen" ||
                layerObj[filename].type == "soldermask")) ||
            (layerObj[filename].side == "all" &&
              (layerObj[filename].type == "outline" ||
                layerObj[filename].type == "drill"))
          ) {
            await this.parserWithTimeLimit({
              timeLimit: this.MAXIMUM_SECONDS_TO_PARSE,
              payload: {
                filename,
                content,
                gerberSide: layerObj[filename].side,
                gerberType:
                  layerObj[filename].side == "all" &&
                  fileext.toLowerCase() == "drd"
                    ? "drill"
                    : layerObj[filename].type,
              },
            });
          } else {
            validFiles.other.push(validFiles.mapped[i]);
          }
        }
        this.toggleLoading();
        this.isLoading = false;
        this.otherFiles = validFiles.other;
        this.initLayers();
        if (this.gerberFiles.length === 0 && this.gerber_files) {
          this.$store.dispatch("ui/snackLink", {
            message1:
              "We did not detect any valid gerber layers in the uploaded zip. Follow  ",
            message2: "  for instructions.",
            email: false,
            link: {
              url:
                "https://fixturfab.com/articles/uploading-gerbers-to-fixturfab/",
              text: "this guide",
            },
          });
        } else if (!this.$refs.form.validate()) {
          console.log(
            "MISSING LAYER",
            `${this.topCopperMess} ${this.topSolderMaskMess} ${this.topSilkscreenrMess} ${this.bottomCopperMess} ${this.bottomSoldermaskMess} ${this.bottomSilkscreenMess} ${this.allMechanicalMess} ${this.allDrillMess}`
          );
          this.$store.commit(
            "ui/SNACK_BAR",
            `Missing the following layers [${this.topCopperMess} ${this.topSolderMaskMess} ${this.topSilkscreenrMess} ${this.bottomCopperMess} ${this.bottomSoldermaskMess} ${this.bottomSilkscreenMess} ${this.allMechanicalMess} ${this.allDrillMess}]`
          );
        }
      } catch (err) {
        if (this.uiLoading.status) {
          this.toggleLoading();
          this.isLoading = false;
        }
        if (this.zipGerberFiles.length > 0) this.initializeLists();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message.split(":").pop() || "Only zip files can be uploaded."
        );
      }
    },
    gerberSvgHandler({
      gerberPk = null,
      filename,
      content,
      gerberSide,
      gerberType,
    }) {
      return new Promise((resolve, reject) => {
        gerberToSvg(content, { color: "#07568e" }, (error, svg) => {
          if (error) {
            reject("gerber to svg error - " + error.message);
          }
          const width = new DOMParser()
            .parseFromString(svg, "image/svg+xml")
            .documentElement.getAttribute("width");
          const height = new DOMParser()
            .parseFromString(svg, "image/svg+xml")
            .documentElement.getAttribute("height");
          const actionPayload = {
            valid:
              +width + +height !== 0 &&
              gerberSide &&
              gerberType &&
              (gerberSide == "top" ||
                gerberSide == "bottom" ||
                gerberSide == "all")
                ? true
                : false,
            payload: {
              side:
                gerberSide == "top"
                  ? "1 - Top Layer"
                  : gerberSide == "bottom"
                  ? "2 - Bottom Layer"
                  : gerberType == "outline"
                  ? "3 - Mechanical Layers"
                  : "4 - Drill Layers",
              data: [
                {
                  pk: gerberPk,
                  filename,
                  gerber: content,
                  svg: svg,
                  type: gerberType,
                  side: gerberSide,
                },
              ],
            },
          };
          this.$store.dispatch("dutconfigs/saveZipGerberFiles", actionPayload);
          resolve(svg);
        });
      });
    },
    async parserWithTimeLimit({ timeLimit, payload }) {
      let timeout;
      const timeoutPromise = new Promise((resolve) => {
        timeout = setTimeout(() => {
          resolve(null);
        }, timeLimit);
      });

      const response = await Promise.race([
        this.gerberSvgHandler(payload),
        timeoutPromise,
      ]);
      !response && this.invalidGerbers.push(payload.filename);
      timeout && clearTimeout(timeout);
    },
    loadSavedGerbers() {
      if (this.savedGerbers.length == 0) return;
      this.toggleLoading("Parsing saved Gerber Files");
      this.$store.commit("dutconfigs/INIT_ZIP_GERBER_FILES");
      this.isLoading = true;

      for (let i = 0; i < this.savedGerbers.length; i++) {
        const actionPayload = {
          valid: true,
          payload: {
            side:
              this.savedGerbers[i].side == "top"
                ? "1 - Top Layer"
                : this.savedGerbers[i].side == "bottom"
                ? "2 - Bottom Layer"
                : this.savedGerbers[i].layer == "outline"
                ? "3 - Mechanical Layers"
                : "4 - Drill Layers",
            data: [
              {
                pk: this.savedGerbers[i].id,
                filename: this.savedGerbers[i].file.split("/").pop(),
                gerber: null,
                svg: this.savedGerbers[i].svg_data,
                type: this.savedGerbers[i].layer,
                side: this.savedGerbers[i].side,
              },
            ],
          },
        };
        console.log(actionPayload);
        this.$store.dispatch("dutconfigs/saveZipGerberFiles", actionPayload);
      }
      this.isLoading = false;
      this.toggleLoading();
      this.initLayers();
    },
    async updateRender({ gerberFile, side, type, gerberFormName }) {
      if (!gerberFile) return;
      console.log(gerberFile, side, type);
      try {
        let content;
        const reader = new FileReader();
        reader.onload = async (res) => {
          content = res.target.result;
          const layerObj = whatsThatGerber([gerberFile.name]);
          console.log("What's-that-gerber fromn Single File", layerObj);
          console.log("Content", content);
          if (
            (layerObj[gerberFile.name].side == "top" &&
              (layerObj[gerberFile.name].type == "copper" ||
                layerObj[gerberFile.name].type == "silkscreen" ||
                layerObj[gerberFile.name].type == "soldermask")) ||
            (layerObj[gerberFile.name].side == "bottom" &&
              (layerObj[gerberFile.name].type == "copper" ||
                layerObj[gerberFile.name].type == "silkscreen" ||
                layerObj[gerberFile.name].type == "soldermask")) ||
            (layerObj[gerberFile.name].side == "all" &&
              (layerObj[gerberFile.name].type == "outline" ||
                layerObj[gerberFile.name].type == "drill"))
          ) {
            let response;
            if (
              layerObj[gerberFile.name].side == side &&
              layerObj[gerberFile.name].type == type
            ) {
              response = await this.gerberSvgHandler({
                filename: gerberFile.name,
                content,
                gerberSide: layerObj[gerberFile.name].side,
                gerberType: layerObj[gerberFile.name].type,
              });
            } else {
              response = await this.gerberSvgHandler({
                filename: gerberFile.name,
                content,
                gerberSide: side,
                gerberType: type,
              });
            }
            this.initLayersWithResponse(response);
          } else {
            const customMessage =
              layerObj[gerberFile.name].side && layerObj[gerberFile.name].type
                ? `You tried to upload a ${side} ${type} layer file, [${
                    gerberFile.name
                  }]. However, we detected this as a [${
                    layerObj[gerberFile.name].side
                  }] [${layerObj[gerberFile.name].type}] file.`
                : `You tried to upload a ${side} ${type} layer file, [${gerberFile.name}]. However, we detected a non-valid gerber layer file.`;
            this.$store.dispatch("ui/snackLink", {
              message1: `${customMessage}.  Follow  `,
              message2: "  for instructions.",
              email: false,
              link: {
                url:
                  "https://fixturfab.com/articles/uploading-gerbers-to-fixturfab/",
                text: "this guide",
              },
            });
            this[gerberFormName] = null;
          }
        };
        reader.onerror = (err) => console.log(err);
        reader.readAsText(gerberFile);
      } catch (err) {
        if (this.uiLoading.status) {
          this.toggleLoading();
          this.isLoading = false;
        }
        if (this.zipGerberFiles.length > 0) this.initializeLists();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message.split(":").pop() || "Only zip files can be uploaded."
        );
      }
    },
    async updateMechanical({ gerberFile, side, type, gerberFormName }) {
      if (!gerberFile) return;
      console.log(gerberFile, side, type);
      try {
        let content;
        for (let i = 0; i < gerberFile.length; i++) {
          const reader = new FileReader();
          reader.onload = async (res) => {
            content = res.target.result;
            const layerObj = whatsThatGerber([gerberFile[i].name]);
            console.log("What's-that-gerber fromn Single File", layerObj);
            console.log("Content", content);
            if (
              (layerObj[gerberFile[i].name].side == "top" &&
                (layerObj[gerberFile[i].name].type == "copper" ||
                  layerObj[gerberFile[i].name].type == "silkscreen" ||
                  layerObj[gerberFile[i].name].type == "soldermask")) ||
              (layerObj[gerberFile[i].name].side == "bottom" &&
                (layerObj[gerberFile[i].name].type == "copper" ||
                  layerObj[gerberFile[i].name].type == "silkscreen" ||
                  layerObj[gerberFile[i].name].type == "soldermask")) ||
              (layerObj[gerberFile[i].name].side == "all" &&
                (layerObj[gerberFile[i].name].type == "outline" ||
                  layerObj[gerberFile[i].name].type == "drill"))
            ) {
              let response;
              if (
                layerObj[gerberFile[i].name].side == side &&
                layerObj[gerberFile[i].name].type == type
              ) {
                response = await this.gerberSvgHandler({
                  filename: gerberFile[i].name,
                  content,
                  gerberSide: layerObj[gerberFile[i].name].side,
                  gerberType: layerObj[gerberFile[i].name].type,
                });
              } else {
                response = await this.gerberSvgHandler({
                  filename: gerberFile[i].name,
                  content,
                  gerberSide: side,
                  gerberType: type,
                });
              }
              this.initLayersWithResponse(response);
            } else {
              const customMessage =
                layerObj[gerberFile[i].name].side &&
                layerObj[gerberFile[i].name].type
                  ? `You tried to upload a ${side} ${type} layer file, [${
                      gerberFile[i].name
                    }]. However, we detected this as a [${
                      layerObj[gerberFile[i].name].side
                    }] [${layerObj[gerberFile[i].name].type}] file.`
                  : `You tried to upload a ${side} ${type} layer file, [${gerberFile[i].name}]. However, we detected a non-valid gerber layer file.`;
              this.$store.dispatch("ui/snackLink", {
                message1: `${customMessage}  Follow  `,
                message2: "  for instructions.",
                email: false,
                link: {
                  url:
                    "https://fixturfab.com/articles/uploading-gerbers-to-fixturfab/",
                  text: "this guide",
                },
              });
              this[gerberFormName] = null;
            }
          };
          reader.onerror = (err) => console.log(err);
          reader.readAsText(gerberFile[i]);
        }
      } catch (err) {
        if (this.uiLoading.status) {
          this.toggleLoading();
          this.isLoading = false;
        }
        if (this.zipGerberFiles.length > 0) this.initializeLists();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message.split(":").pop() || "Only zip files can be uploaded."
        );
      }
    },
    async zipFileParser(payload) {
      try {
        const zip = new JSZip();
        const response = await zip.loadAsync(payload);
        const validFiles = Object.keys(response.files).reduce(
          (reducer, element) => {
            if (element.split("/").pop().length === 0) {
              reducer.dir.push(element);
            } else if (element.split(".").pop() !== "art") {
              reducer.mapped.push(element);
            } else {
              reducer.art.push(element);
            }
            return reducer;
          },
          { dir: [], mapped: [], art: [], other: [] }
        );
        this.toggleLoading("Converting zip file to Gerber layer files");
        this.isLoading = true;
        const tsBefore = new Date();
        let loaderUpdated = false;
        for (let i = 0; i < validFiles.mapped.length; i++) {
          const tsAfter = new Date();
          if (
            !loaderUpdated &&
            tsAfter - tsBefore >= this.MAXIMUM_SECONDS_PARSE_LONG
          ) {
            this.toggleLoading();
            this.toggleLoading(
              "Still parsing your gerbers, they might be too large"
            );
            loaderUpdated = true;
          }
          const fileObj = validFiles.mapped[i];
          const filename = fileObj.split("/").pop();
          const fileext = filename.split(".").pop();
          const content = await zip.file(fileObj).async("nodebuffer");
          const layerObj = whatsThatGerber([filename]);
          console.log("What's-that-gerber", layerObj);
          if (
            (layerObj[filename].side == "top" &&
              (layerObj[filename].type == "copper" ||
                layerObj[filename].type == "silkscreen" ||
                layerObj[filename].type == "soldermask")) ||
            (layerObj[filename].side == "bottom" &&
              (layerObj[filename].type == "copper" ||
                layerObj[filename].type == "silkscreen" ||
                layerObj[filename].type == "soldermask")) ||
            (layerObj[filename].side == "all" &&
              (layerObj[filename].type == "outline" ||
                layerObj[filename].type == "drill"))
          ) {
            await this.parserWithTimeLimit({
              timeLimit: this.MAXIMUM_SECONDS_TO_PARSE,
              payload: {
                filename,
                content,
                gerberSide: layerObj[filename].side,
                gerberType:
                  layerObj[filename].side == "all" &&
                  fileext.toLowerCase() == "drd"
                    ? "drill"
                    : layerObj[filename].type,
              },
            });
          } else {
            validFiles.other.push(validFiles.mapped[i]);
          }
        }
        this.toggleLoading();
        this.isLoading = false;
        this.otherFiles = validFiles.other;
        this.initLayers();
        if (this.gerberFiles.length === 0 && this.gerber_files) {
          this.$store.dispatch("ui/snackLink", {
            message1:
              "We did not detect any valid gerber layers in the uploaded zip. Follow  ",
            message2: "  for instructions.",
            email: false,
            link: {
              url:
                "https://fixturfab.com/articles/uploading-gerbers-to-fixturfab/",
              text: "this guide",
            },
          });
        } else if (!this.$refs.form.validate()) {
          this.$store.commit(
            "ui/SNACK_BAR",
            `Missing the following layers [${this.topCopperMess} ${this.topSolderMaskMess} ${this.topSilkscreenrMess} ${this.bottomCopperMess} ${this.bottomSoldermaskMess} ${this.bottomSilkscreenMess} ${this.allMechanicalMess} ${this.allDrillMess}]`
          );
        }
      } catch (err) {
        if (this.uiLoading.status) {
          this.toggleLoading();
          this.isLoading = false;
        }
        if (this.zipGerberFiles.length > 0) this.initializeLists();
        this.$store.commit(
          "ui/SNACK_BAR",
          "Only valid zip files can be converted."
        );
      }
    },
    updateAdminMode(payload) {
      this.disableAdminMode = payload;
    },
  },
};
